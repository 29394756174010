<template>
  <div >
    <Header v-if="isPc"></Header>
    <!-- <keep-alive>
        <router-view v-if="$route.meta.keepAlive" :key="$route.fullPath" id="router-view"></router-view>
    </keep-alive> -->
    <router-view  id="router-view" :class="isPc?'pc':'mobile'"></router-view>
    <Footer v-show="$route.name!='bkcode'&&$route.name!='bkexplain'&&isPc"></Footer>
    <TabView v-if="!isPc&&$route.meta.showTab"></TabView>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import {getInfoDetail} from '../api/base'
import {getCookie,setCookie} from '../util/index'
import Footer from '@/components/Footer'
import Header from '@/components/HomeHeader'
import TabView from '@/components/TabView.vue'
import { isPcWindow} from '@/util/index'
export default {
    name: 'Home',
    data() {
      return {
        isPc:isPcWindow(),
      }
    },
    watch: {
      isPc: {
        handler(val) {
          this.$store.commit('set_isPc',this.isPc)
          location.reload()
        },
      }
    },
    components: {
      Footer,
      Header, TabView
    },
    created() {
      this.$store.commit('set_isPc',this.isPc)
      if(!localStorage.getItem('userInfo')&&getCookie('access-token')){
        this.getInfoDetail()
      }
    },
    computed: {
			
		},
    methods: {
      async getInfoDetail(){
        console.log('2')
        let res=await getInfoDetail()
        if(res){
          localStorage.setItem('userInfo',JSON.stringify(res.data))
          this.$store.commit("set_login_mess", res.data);
          setCookie('userId',res.data.userId+'')
					// res.data.identitys.forEach((item) => {
					// 	if (item.isCurrent == 1) {
					// 		this.$store.commit("set_login_mess", item);
					// 	}
					// });
        }
      },
    },
    mounted(){
      window.addEventListener('resize', () => {
        this.isPc=isPcWindow()
      })
    },
    activated(){
      console.log('触发activated')
    }
    
}
</script>
<style lang="scss" scoped>
// .bottom-info{min-width: auto;}
</style>
