<template>
				<div class="login-box">
			<p class="title">忘记密码</p>
				<div class="form-contain"  >
					<el-form ref="ruleForm" :model="form" :rules="rules"   label-position="top" :hide-required-asterisk="true">
						<el-form-item label="手机号" prop="phone">
							<el-input v-model.number="form.phone"   placeholder="请输入手机号"  :readonly="readonlyInput"
			@focus="cancelReadOnly()">
							</el-input>
						</el-form-item>
						<el-form-item label="新密码" prop="password">
							<el-input v-model="form.password" maxlength="16" minlength="6" show-password placeholder="请输入新密码"  :readonly="readonlyInput1"
			@focus="cancelReadOnly1()">
							</el-input>
						</el-form-item>
						<el-form-item label="确认新密码" prop="confirm_password">
							<el-input v-model="form.confirm_password" maxlength="16" minlength="6" show-password placeholder="确认新密码" :readonly="readonlyInput2"
			@focus="cancelReadOnly2()">
							</el-input>
						</el-form-item>
						<el-form-item label="验证码" prop="mobileCode">
							<div class="flex_between">
							<el-input  maxlength="6" minlength="4"  v-model="form.mobileCode" placeholder="请输入验证码" 
								>
								</el-input>
							<span class="code-button" @click="getCode">
								{{showCode?'&nbsp;&nbsp;重新发送('+num+')':'&nbsp;&nbsp;发送验证码'}}</span>
						</div>
						</el-form-item>
					</el-form>
					<div @click="login('ruleForm')" class="login-btn flex_center">
						确认
					</div>

				</div>
		</div>
</template>
<script>
import Forget from './Forget'
export default Forget
</script>
  <style lang="scss" scoped>
  ::v-deep .el-form-item{margin-bottom: 32px;}
    ::v-deep .el-form-item__label{padding: 0 0 8px 0;font-size: 16px;
font-family: PingFangSC-Semibold, PingFang SC;
font-weight: 600;
color: #2B2C33;
line-height: 24px;}
  .login-box{
	.code-button{
	position: absolute;
    right: 16px;
   cursor: pointer;
   font-size: 14px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #4260FD;
line-height: 20px;
}
	.title{
		font-size: 32px;
font-family: PingFang-SC-Bold, PingFang-SC;
font-weight: bold;
color: #2B2C33;
line-height: 40px;
position: relative;
margin-bottom: 32px;
	}
	.title::after{position: absolute;content: '';width: 62px;
height: 12px;
background: linear-gradient(90deg, #4260FD 0%, #FFFFFF 100%);bottom: -8px;left: 0;}
::v-deep .el-form-item__label{font-size: 16px;
font-family: PingFangSC-Semibold, PingFang SC;
font-weight: 600;
color: #2B2C33;
line-height: 24px;}
::v-deep .el-input__inner{
	background: #FFFFFF;
border-radius: 8px;
border: 1px solid #E2E3E6;
height: 48px;
line-height: 48px;
}
.login-btn{
	width: 184px;
height: 48px;
background: linear-gradient(90deg, #6F9EFF 0%, #4260FD 100%);
box-shadow: 0px 4px 6px 0px rgba(66,96,253,0.24);
border-radius: 8px;
font-size: 18px;
font-family: PingFang-SC-Bold, PingFang-SC;
font-weight: bold;
color: #FFFFFF;
line-height: 26px;
margin: 32px auto 0;
cursor: pointer;
}
}
</style>