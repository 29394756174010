import html2canvas from 'html2canvas'
import {upLoadFile} from './upload.js'
import jsPDF from 'jspdf'
export default {
    install(Vue, options) {
      Vue.prototype.getPdf = function (title,el,download=false,callback) {
          //DPF标题
          html2canvas(el, {
            allowTaint: true,
            taintTest: false,
            useCORS: true,
            y:4, // 对Y轴进行裁切
            // width:1200,
            // height:5000,
            dpi: window.devicePixelRatio * 1, //将分辨率提高到特定的DPI 提高四倍
            scale: 1 //按比例增加分辨率 
          }).then(function (canvas) {
            let contentWidth = canvas.width
            let contentHeight = canvas.height
            let pageHeight = contentWidth / 592.28 * 841.89
            let leftHeight = contentHeight
            let position = 20
            let imgWidth = 595.28
            let imgHeight = 592.28 / contentWidth * contentHeight
            let pageData = canvas.toDataURL('image/jpeg', 1.0)
            let PDF = new jsPDF('', 'pt', 'a4')
            if (leftHeight < pageHeight) {
              PDF.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight)
            } else {
              while (leftHeight > 0) {
                PDF.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
                leftHeight -= pageHeight
                position -= 841.89
                if (leftHeight > 0) {
                PDF.addPage()
              }
            }
          }
       
          if(!download){
            PDF.save(title + '.pdf')
            callback()
          }else{
            var pdfData = PDF.output('datauristring')
            let file=dataURLtoFile(pdfData,title)
            console.log(file)
            upLoadFile(file,file.name,'.pdf',(url)=>{
                console.log(url)
                callback(url)
            })
           
          }
         
          
         
        })
      }
      let dataURLtoFile=(dataurl, filename)=>{
        var arr = dataurl.split(',');
        var mime = arr[0].match(/:(.*?);/)[1];
        var bstr = atob(arr[1]);
        var n = bstr.length; 
        var u8arr = new Uint8Array(n);
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        //转换成file对象
        return new File([u8arr], filename, {type:mime});
        //转换成成blob对象
        //return new Blob([u8arr],{type:mime});
      }
    }
  }


