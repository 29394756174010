
const getters = {
    basic_static:state => state.user.basic_static,
    domain_static:state => state.user.domain_static,
    basic_api_url:state => state.user.basic_api_url,
    stage_list:state => state.user.stage_list,
    grade_list:state => state.user.grade_list,
    subject_list:state => state.user.subject_list,
    book_chapter_list:state => state.user.book_chapter_list,
    knowledge_list:state => state.user.knowledge_list,
    grade_list_index:state => state.user.grade_list_index,
    subject_list_index:state => state.user.subject_list_index,
    book_chapter_list_index:state => state.user.book_chapter_list_index,
    knowledge_list_index:state => state.user.knowledge_list_index,
    access_token: state => state.user.access_token,
    login_mess:state => state.user.login_mess,
    edition_list_index: state => state.user.edition_list_index,
    edition_list: state => state.user.edition_list,
    book_list:state => state.user.book_list,
    book_list_index:state => state.user.book_list_index,
    chapter_list:state => state.user.chapter_list,
    chapter_list_index:state => state.user.chapter_list_index,
    node_list:state => state.user.node_list,
    node_list_index:state => state.user.node_list_index,
    node_child_list:state => state.user.node_child_list,
    node_child_list_index:state => state.user.node_child_list_index,
    login_dialog:state=>state.user.login_dialog,
    menu_list:state=>state.user.menu_list,
    isPc:state=>state.user.isPc,
};
export default getters;
