<template>
		<div class="login-box">
			<p class="title">登录</p>
			<ul class="type-ul flex_between">
				<li :class="type=='企业微信'?'active':''" @click="changeType('企业微信')">企业微信</li>
				<li :class="type=='账号'?'active':''" @click="changeType('账号')">账号</li>
			</ul>
			

			<div class="padding-contain">
				<div class="qrcode-contain flex_column_center" v-if="type=='企业微信'">
					<div class="code-img flex_center">
						<div class="flex_center" v-html="img_url"></div>
						<div class="wrp_code_rl_bg" id="wx_timeout_tips" v-if="showCode">
							<div class="wrp_code_rl_mask"></div>
							<div class="wrp_code_rl_info">
								<i class="status_icon wrp_code_rl_logo"></i>
								<div class="wrp_code_rl_label">当前二维码已过期</div>
								<p class="wrp_code_rl_btn" @click="renewalCode">刷新</p>
							</div>
						</div>
					</div>
					<p class="qywx-title">企业微信扫一扫，立即登录</p>
				</div>
				<div class="form-contain" v-else  style="padding-top:32px">
					<el-form ref="ruleForm" :model="form" :rules="rules" label-position="top" :hide-required-asterisk="true">
						<el-form-item label="用户名" prop="username">
							<el-input v-model.number="form.username" placeholder="请输入用户名" >
							</el-input>
						</el-form-item>
						<el-form-item label="密码" prop="password" style="margin-bottom:8px">
							<el-input v-model="form.password" maxlength="16" minlength="6" show-password 
								placeholder="请输入密码">
							</el-input>
						</el-form-item>
					</el-form>
						<div class="other-help flex_end" >
							<p @click="setPsw">忘记密码</p>
						</div>
					<div @click="login('ruleForm')" class="login-btn flex_center">
						登录
					</div>

				</div>
			</div>
		</div>
</template>
<script>
import login from './Login'
export default login
</script>
  <style lang="scss" scoped>
    ::v-deep .el-form-item{margin-bottom: 32px;}
  ::v-deep .el-form-item__label{padding: 0 0 8px 0;font-size: 16px;
font-weight: 600;
color: #2B2C33;
line-height: 24px;}
.login-box{

	.padding-contain{
		height: 303px;
	}
	.title{
		font-size: 32px;
font-weight: bold;
color: #2B2C33;
line-height: 40px;
position: relative;
margin-bottom: 22px;
	}
	.title::after{position: absolute;content: '';width: 62px;
height: 12px;
background: linear-gradient(90deg, #4260FD 0%, #FFFFFF 100%);bottom: -8px;left: 0;}
.type-ul{
	background: #F9F9F9;
li{width: 50%;padding: 11px 0;text-align: center;font-size: 18px;
font-weight: 400;
color: #6D6F75;
cursor: pointer;
border-bottom: 1px solid #E2E3E6;
line-height: 26px;}
li.active{
	color: #4260FD;
	background: #fff;
	font-weight: 600;
	border-radius: 8px 8px 0px 0px;
	border: 1px solid #E2E3E6;
	border-bottom: 0;
}
}
::v-deep .el-form-item__label{font-size: 16px;
font-weight: 600;
color: #2B2C33;
line-height: 24px;}
::v-deep .el-input__inner{
	background: #FFFFFF;
border-radius: 8px;
border: 1px solid #E2E3E6;
height: 48px;
line-height: 48px;
}
.other-help{
	font-size: 12px;
font-weight: 400;
color: #4260FD;
line-height: 18px;
margin-bottom: 6px;
cursor: pointer;
}
.login-btn{
	width: 184px;
height: 48px;
background: linear-gradient(90deg, #6F9EFF 0%, #4260FD 100%);
box-shadow: 0px 4px 6px 0px rgba(66,96,253,0.24);
border-radius: 8px;
font-size: 18px;
font-weight: bold;
color: #FFFFFF;
line-height: 26px;
margin: 0 auto;
cursor: pointer;
}
.qywx-title{
	font-size: 16px;
font-weight: 400;
color: #4260FD;
line-height: 24px;
}
}
.code-img {
		/* border: 1px solid #E0E0E0; */
		// margin: 10px auto;
		// max-width: 200px;
		position: relative;
		// width: 70%;
		width: 100%;

		::v-deep svg {
			width: 100%;
			height: 100%;
			background-color: transparent !important;
		}
	}
.wrp_code_rl_bg {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
	}

	.wrp_code_rl_mask {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		background: white;
		opacity: 0.9;
		filter: alpha(opacity=90);
	}

	.wrp_code_rl_info {
		color: #222;
		display: inline-block;
		position: absolute;
		height: 110px;
		top: 50%;
		margin-top: -50px;
		width: 115px;
		left: 50%;
		margin-left: -57px;
		text-align: center;
	}

	.impowerBox .status_icon {
		display: inline-block;
		vertical-align: middle;
	}

	.wrp_code_rl_logo {
		width: 30px;
		height: 30px;
		// background: url(//wwcdn.weixin.qq.com/node/wwopen/wwopenmng/style/images/independent/icons/InfoNormal$afb7f20e.png) no-repeat left top;
		// background-size: 30px 30px;
	}

	.wrp_code_rl_label {
		font-size: 14px;
		padding: 15px 0px;
	}

	.wrp_code_rl_btn {
		cursor: pointer;
		background-image: linear-gradient(to bottom, #fff 0, #f8f8f8 100%);
		border-radius: 2px;
		vertical-align: middle;
		border: 1px solid #aaa;
		display: inline-block;
		margin: 0;
		box-sizing: content-box;
		min-width: 24px;
		height: 24px;
		padding: 0 12px;
		background: #f5f5f5;
		line-height: 24px;
		outline: 0;
		text-align: center;
		font-size: 12px;
		color: #222;
		user-select: none;
		text-decoration: none;
	}
</style>
<style >


</style>