import { randomString, getdate, setCookie, getCookie, concatObj } from "./index.js";
import COS from "cos-js-sdk-v5";
import { getCos } from "@/api/base.js";
export async function upLoadFile(file, fileName, type, callback) {
    console.log(file, 'file')
    let  bucketinfo={}
    async function getCosInfo() {
        let res = await getCos();
        if (res) {
            bucketinfo = res.data;
            console.log(res)
        }
    }
    await getCosInfo()
    var key = 'learnspace/' + getdate() + randomString(32, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ') + type;
    var cos = new COS({
        SecretId: bucketinfo.token.credentials.tmpSecretId,
        SecretKey: bucketinfo.token.credentials.tmpSecretKey,
        XCosSecurityToken: bucketinfo.token.credentials.sessionToken,
        StartTime: bucketinfo.token.startTime,
        ExpiredTime: bucketinfo.token.expiredTime,
    });
    cos.putObject(
        {
            Bucket: bucketinfo.bucket /* 必须 */,
            Region: bucketinfo.region /* 存储桶所在地域，必须字段 */,
            Key: key /* 必须 */,
            StorageClass: "STANDARD",
            Body: file, // 上传文件对象
            onProgress: (progressData) => {
                if (progressData.percent == 1) {
                    // console.log(progressData);
                }
            },
        },
        (err, data) => {
            // console.log(err || data);
            if (err) {
                return;
            }
            if (data.statusCode == 200) {
               let word_url = "https://" + data.Location
                callback(word_url)
            }
        }
    );
}