<template>
<div class="flex_start main back" v-if="!$route.meta.noHeader&&title">
   <div class="flex_start back-left" @click="$router.back()">
    <img  :src="basic_static+'learnspace/images/back.png'" />
    <p >返回</p>
   </div>
    <p>/{{title}}</p>
</div>
</template>
<script>
  import {mapGetters, mapActions} from 'vuex';
  export default {
      data() {
          return {
              title:''
          };
      },
      methods: {
       
      },
      created(){
        this.title=this.$route.meta.title
      },
      mounted() {
       
       
        
      },
      watch: {
          $route: {
            handler: function(val, oldVal){
              this.title=val.meta.title
            },
            // 深度观察监听
            deep: true
          }
},
      computed: {
          ...mapGetters(['basic_static','domain_static','login_mess']),
      },
  }
</script>
<style lang="scss" scoped>
.back{
  padding: 19px 0;
  font-size: 16px;
  img{
    width: 22px;
    height: 22px;
    margin-right: 5px;
  }
  .back-left{
    cursor: pointer;
    margin-right: 10px;
  }
}
</style>