
import {ajax} from './axios'
//获取学段
export const getStage = () => {
    return ajax({
        headers: {
            isToken: true,
        },
        url:`/stage/page`,
        method: 'get',
    })
}
//通过学段ID获取年级
export const getGrade = (form) => {
    return ajax({
        headers: {
            isToken: true,
        },
        url:'/grade/bystage?stageId='+form.stageId,
        method: 'get',
    })
}
//通过年级id获取学科
export const getSubject = (form) => {
    return ajax({
        headers: {
            isToken: true,
        },
        url:'/subject/bygrade?stageId='+form.stageId,
        // url:'/subject/bygrade?gradeId='+form.gradeId,
        method: 'get',
    })
}
// 获取册
export const getBook = (cityId) => {
    return ajax({
        headers: {
            isToken: true,
        },
        url:'/book/list',
        method: 'get',
    })
}
// 获取教材--新
export const getNewEdition = (form) => {
    return ajax({
        headers: {
            isToken: true,
        },
        url:`/edition/list?subjectId=${form.subjectId}`,
        // url:`/edition/list?subjectId=${form.subjectId}&gradeId=${form.gradeId}`,
        method: 'get',
    })
}
// 获取册--新
export const getNewBook = (form) => {
    return ajax({
        headers: {
            isToken: true,
        },
        url:`/book/list?subjectId=${form.subjectId}&editionId=${form.editionId}&stageId=${form.stageId ? form.stageId : ''}`,
        // url:`/book/list?subjectId=${form.subjectId}&gradeId=${form.gradeId}&editionId=${form.editionId}`,
        method: 'get',
    })
}
// 获取章（课)--新
export const getNewChapter = (form) => {
    return ajax({
        headers: {
            isToken: true,
        },
        url:`/book-chapter/${form.bookId}/book`,
        method: 'get',
    })
}
// 获取节--新
export const getNewNode = (form) => {
    return ajax({
        headers: {
            isToken: true,
        },
        url:`/book-chapter/chapter/${form.parentId}`,
        method: 'get',
    })
}
// 获取小节--新
export const getNewNodeChild = (form) => {
    return ajax({
        headers: {
            isToken: true,
        },
        url:`/book-chapter/chapter/${form.parentId}`,
        method: 'get',
    })
}
// 获取知识点--新
export const getNewKnowledge = (form) => {
    return ajax({
        headers: {
            isToken: true,
        },
        url:`/book-chapter-knowledge/list/${form.nodeId}`,
        method: 'get',
    })
}
// 获取章（课)
export const getChapter = (cityId) => {
    return ajax({
        headers: {
            isToken: true,
        },
        url:`/book-chapter/chapter`,
        method: 'get',
    })
}
// 获取节
export const getNode = (cityId) => {
    return ajax({
        headers: {
            isToken: true,
        },
        url:`/book-chapter/node`,
        method: 'get',
    })
}
// 获取知识点
export const getKnowledge = (cityId) => {
    return ajax({
        headers: {
            isToken: true,
        },
        url:`/book-chapter-knowledge/list`,
        method: 'get',
    })
}

//获取学科下的知识点树
export const getKnowLedgeTree = (obj) => {
    return ajax({
        headers: {
            isToken: true,
        },
        url:`/knowledge/tree?subjectId=${obj.subjectId}`,
        method: 'get',
    })
}
//获取上传到腾讯云对象的cos
export function getCos(opt) {
    return ajax({
        headers: {
            isToken: true,
        },
        url:`/base/cos`,
        method: 'get',
    })
}
//获取上传到云点播
export function getVod(opt) {
    return ajax({
        url:'/base/vod/sign',
        method:'get',
        headers: {
            isToken: true,
        },
    });
}
//云点播播放密钥
export const getVodUrl = (obj) => {
    return ajax({
        url: `/base/vod/play`,
        method:'post',
        data:obj,
        headers: {
            isToken: false,
        },
    })
}
//获取某个城市下的所有教材版本和章（课)节树
export const getBookChapter = (obj) => {
    return ajax({
        url: `/config/book-chapter/bookedition/tree?subjectId=${obj.subjectId}&cityId=${obj.cityId}`,
        // url: `/config/book-chapter/bookedition/tree?subjectId=${obj.subjectId}&gradeId=${obj.gradeId}&cityId=${obj.cityId}`,
        method: 'post',
        data:obj,
        headers: {
            isToken: true,
        },
    })
}
//添加文件
export const addFile = (obj) => {
    return ajax({
        url: `/teach/file`,
        method: 'post',
        data:obj,
        headers: {
            isToken: true,
        },
    })
}
//获取登录token
export const getToken = (obj) => {
    return ajax({
        url: `/user/info/token/${obj.userId}`,
        method: 'post',
        data:obj,
        headers: {
            isToken: false,
        },
    })
}
//获取登录信息
export const getInfoDetail = () => {
    return ajax({
        url: `/user/info/detail`,
        method: 'get',
        headers: {
            isToken: true,
        },
    })
}
//获取特色课程目录
export const specialCourse = () => {
    return ajax({
        url: `/dict/special/course`,
        method: 'get',
        headers: {
            isToken: true,
        },
    })
}
//新增章节
export const addChapterLesson = (obj) => {
    return ajax({
        url: `/teach/add/chapter-lesson`,
        method: 'post',
        data:obj,
        headers: {
            isToken: false,
        },
    })
}