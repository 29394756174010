<template>
    <ul class="tab flex_around">
        <li :class="tab_id==item.id?'flex_column_center active':'flex_column_center'" v-for="item in tab_list" @click="changeTab(item)">
            <img :src="tab_id==item.id ? basic_static + 'learnspace/images/mobile/index/' + item.active_icon : basic_static + 'learnspace/images/mobile/index/' + item.icon" >
            <p>{{item.title}}</p>
        </li>

    </ul>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { clearCookie, getCookie, noToken } from "../util/index";
export default {
    data() {
        return {
            tab_id:'1',
            tab_list:[
                {
                    id:1,
                    path:'/homepage/index',
                    title:'首页',
                    icon:'tab-index.svg',
                    active_icon:'tab-index-active.svg',
                },
                {
                    id:2,
                    path:'/space/digital',
                    title:'数字化教学',
                    icon:'tab-digital.svg',
                    active_icon:'tab-digital-active.svg'
                },
                {
                    id:3,
                    path:'/resource/index',
                    title:'资源中心',
                    icon:'tab-resource.svg',
                    active_icon:'tab-resource-active.svg'
                },
                {
                    id:4,
                    path:'/space/my',
                    title:'我的',
                    icon:'tab-my.svg',
                    active_icon:'tab-my-active.svg'
                },
            ]
        }
    },
    methods: {
        changeTab(item){
            console.log(item,111111)
            if(item.id==1||item.id==4){
                this.$router.push(item.path)
            }else{
                if(noToken()){
                    this.$router.push(item.path)
                }
            }
        },
    },
    watch: {
        $route: {
            handler: function (val, oldVal) {
                if (this.$route.path.indexOf("space") > -1&&this.$route.path!="/space/my") {
						this.tab_id = 2;
					}else if (this.$route.path=="/space/my") {
						this.tab_id = 4;
					}  else if (this.$route.path.indexOf("resource") > -1) {
						this.tab_id = 3;
					} else {
						this.tab_id = 1;
					}
				},
            // 深度观察监听
            deep: true
        },
        'login_mess.identity': {
            handler: function (val, oldVal) {
                if(this.login_mess.identity==0){
                    this.tab_list=[
                        {
                    id:1,
                    path:'/homepage/index',
                    title:'首页',
                    icon:'tab-index.svg',
                    active_icon:'tab-index-active.svg',
                },
                {
                    id:3,
                    path:'/resource/index',
                    title:'资源中心',
                    icon:'tab-resource.svg',
                    active_icon:'tab-resource-active.svg'
                },
                {
                    id:4,
                    path:'/space/my',
                    title:'我的',
                    icon:'tab-my.svg',
                    active_icon:'tab-my-active.svg'
                },
                    ]
                }else{
                    this.tab_list=[
                        {
                    id:1,
                    path:'/homepage/index',
                    title:'首页',
                    icon:'tab-index.svg',
                    active_icon:'tab-index-active.svg',
                },
                {
                    id:2,
                    path:'/space/digital',
                    title:'数字化教学',
                    icon:'tab-digital.svg',
                    active_icon:'tab-digital-active.svg'
                },
                {
                    id:3,
                    path:'/resource/index',
                    title:'资源中心',
                    icon:'tab-resource.svg',
                    active_icon:'tab-resource-active.svg'
                },
                {
                    id:4,
                    path:'/space/my',
                    title:'我的',
                    icon:'tab-my.svg',
                    active_icon:'tab-my-active.svg'
                },
                    ]
                }
                if(!this.login_mess.identity&&this.login_mess.identity!=0){
                    this.token=null
                }else{
                    this.token = getCookie("access-token");
                }
            },
            // 深度观察监听
            deep: true,
		},
    },
    created() {
        if (this.$route.path.indexOf("space") > -1&&this.$route.path!="/space/my") {
						this.tab_id = 2;
					} else if (this.$route.path.indexOf("resource") > -1) {
						this.tab_id = 3;
					}else if (this.$route.path=="/space/my") {
						this.tab_id = 4;
					} else {
						this.tab_id = 1;
					}
                    if(this.login_mess.identity==0){
                    this.tab_list=[
                        {
                    id:1,
                    path:'/homepage/index',
                    title:'首页',
                    icon:'tab-index.svg',
                    active_icon:'tab-index-active.svg',
                },
                {
                    id:3,
                    path:'/resource/index',
                    title:'资源中心',
                    icon:'tab-resource.svg',
                    active_icon:'tab-resource-active.svg'
                },
                {
                    id:4,
                    path:'/space/my',
                    title:'我的',
                    icon:'tab-my.svg',
                    active_icon:'tab-my-active.svg'
                },
                    ]
                }else{
                    this.tab_list=[
                        {
                    id:1,
                    path:'/homepage/index',
                    title:'首页',
                    icon:'tab-index.svg',
                    active_icon:'tab-index-active.svg',
                },
                {
                    id:2,
                    path:'/space/digital',
                    title:'数字化教学',
                    icon:'tab-digital.svg',
                    active_icon:'tab-digital-active.svg'
                },
                {
                    id:3,
                    path:'/resource/index',
                    title:'资源中心',
                    icon:'tab-resource.svg',
                    active_icon:'tab-resource-active.svg'
                },
                {
                    id:4,
                    path:'/space/my',
                    title:'我的',
                    icon:'tab-my.svg',
                    active_icon:'tab-my-active.svg'
                },
                    ]
                }
    },
    computed: {
        ...mapGetters(['login_mess','basic_static']),    
    },
}
</script>
<style lang="scss" scoped>
@import "../style/element.scss";
.tab{ padding: 10px 0 15px; background: #fff; position: fixed; bottom: 0; width: 100%; left: 50%; transform: translateX(-50%); max-width: 900px; z-index: 10;border-top:1px solid $line;
    p{color: $grey3;;font-size: 10px;margin-top: 2px;}
    img{width: 24px;height: 24px;}
    li{cursor: pointer;width: 30%;}
    li.active{p{color: $blue1;}}
}
</style>