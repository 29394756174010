<template>
  <div id="app">
      <router-view  id="router-view"></router-view>
    <!-- <Footer></Footer> -->
  </div>
</template>
<script>
// import Footer from '@/components/Footer'
export default {
  name: 'app',
  data() {
      return {

          
      };
  },
  components:{},
  methods: {
    
  },
  created(){
    
  }
}
</script>
<style>
  @import 'style/public.css'; /*引入公共样式*/
  body{
  padding-right: 0px !important;
  /* overflow: auto !important; */
}
</style>

