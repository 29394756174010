<template>
  <div >
    <Header ></Header>
    <Back v-if="$route.name!='classroom/index'&&$route.name!='space/tk/work'&&$route.name!='space/tk/myQuestion'"></Back>
    <keep-alive>
        <router-view v-if="$route.meta.keepAlive" :key="$route.fullPath" id="router-view"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" :key="$route.fullPath" id="router-view"></router-view>
  </div>
</template>

<script>
import  Header  from "../components/Header.vue";
import  Back  from "../components/Back.vue";
import { mapGetters, mapActions } from "vuex";
import {getInfoDetail} from '../api/base'
import {setCookie} from '../util/index'
export default {
    name: 'Home',
    data() {
      return {
        
      }
    },
    components: {
      Header,
      Back,
    },
    created() {
      this.getInfoDetail()
    },
    computed: {
			
		},
    methods: {
      async getInfoDetail(){
        let res=await getInfoDetail()
        if(res){
          localStorage.setItem('userInfo',JSON.stringify(res.data))
          this.$store.commit("set_login_mess", res.data);
          setCookie('userId',res.data.userId+'')
					// res.data.identitys.forEach((item) => {
					// 	if (item.isCurrent == 1) {
					// 		this.$store.commit("set_login_mess", item);
					// 	}
					// });
        }
      },
    },
    
}
</script>
<style lang="scss" scoped>

</style>
