import axios from 'axios'
import { Message, MessageBox } from 'element-ui';
import { Notify,Dialog } from 'vant';
// import qs from 'qs';
import { getCookie, clearCookie, setCookie,getQueryVariable,isPcWindow} from '@/util/index';
import store from '@/store'
var flag=true
var domain_static = 'https://xxkj.glzhxx.com/studyspace';//域名
var basic_api_url = 'https://xxkj.glzhxx.com/study-api';//接口
var basic_static='https://cdn.tqxxkj.cn/static/';//静态资源
// var basic_static = 'http://localhost/static/';//静态资源本地
const ajax = axios.create({
    baseURL: basic_api_url,
    withCredentials: true,
    timeout: 50000,
    // headers:{
    //     'Content-Type': 'application/json;charset=utf-8',
    // },
})
let url = window.location.href;
let api_token = '';
let arr = {}
if( getQueryVariable('data')){
    let data = getQueryVariable('data')
    let list = Base64.decode(data).split('&')
    list.forEach(item => {
        arr[item.split('=')[0]] = item.split('=')[1]
    })
}
console.log(url.indexOf("token"),222)
if (url.indexOf("token") != -1) {
    api_token = getQueryVariable('token')
    setCookie('access-token',api_token);
    if (arr.type == 'correct') {
        location.href = 'https://xxkj.glzhxx.com/studyspace/#/homeworkDetail?distributeId=' + arr.distributeId + '&hdId=' + arr.hdId
    } else if (arr.type == 'distribute') {
        location.href = 'https://xxkj.glzhxx.com/studyspace/mobilelearnspace/#/homeworkList'
    } 
    // else {
    //     location.href = 'https://xxkj.glzhxx.com/mobilelearnspace'
    // }
}

ajax.interceptors.request.use(//发送请求的时候处理的，统一加上api_token
    (config) => {
        // Do something before request is sent
        if (!config.data) {
            config.data = []
        }
        const isToken = (config.headers || {}).isToken
        if(isToken){
            config.headers.Authorization = 'Bearer '+getCookie('access-token')
        }
        return config;
    },
    (error) =>
        // Do something with request error
        Promise.reject(error),
)

ajax.interceptors.response.use(
    (response) => {
        if(response.status==200&&response.config.url=='/account/qw_code'){
            return response.data
        }
        if(response.data.code == 0){
            return response.data;
        }else if(response.data.code == -1){//表示登录失效
                clearCookie()
                localStorage.removeItem('userInfo')
                sessionStorage.clear()
                console.log(flag,'flag')
                if(window.location.href.indexOf('/homepage/index')>-1){
                    // window.location.href = 'https://xxkj.glzhxx.com/studyspace/#/homepage/index'
                    return
                }
                if(!flag){
                    return
                }
                flag=false
                // let redirect=''
                // if(window.location.href.indexOf('/space/bk/code')>-1){
                //     redirect='code'
                // }
                    MessageBox.confirm(`当前登录状态已过期，请重新登录`, '系统提示', {
                        confirmButtonText: '登录',
                        showCancelButton:true,
                        closeOnClickModal:false,
                        type: 'warning'
                    }
                    ).then(() => {
                    //     // 去登陆
                    // flag=true
                if(isPcWindow()){

                    store.commit('set_login_dialog',true)
                }else{
                    window.location.href = 'https://xxkj.glzhxx.com/studyspace/#/login'
                }
                        // window.location.href = 'https://xxkj.glzhxx.com/studyspace/#/homepage/index'
                    }).catch(() => {
                        flag=true
                    })
             
        }else{
            if(isPcWindow()){
                // if(window.location.href.indexOf('/homepage/index')>-1){
                    // window.location.href = 'https://xxkj.glzhxx.com/studyspace/#/homepage/index'
                //     return
                // }
                Message.error(response.data.msg)
            }else{
                Notify({ type: 'danger', message:response.data.msg });
            }
        }
    },
    (error) => {
        if (error && error.response) {
            // if (error.response.status === 424&&url.indexOf('index')>-1&&error.response.config.url=='/user/info/detail') {
            //     clearCookie()
            //     localStorage.removeItem('userInfo')
            //     // window.location.href = 'https://xxkj.glzhxx.com/homepage/#/index'
            //     return
            // }
            // if (error.response.status === 424) {
            //     if(!flag){
            //         return
            //     }
            //     flag=false
            //     clearCookie()
            //     localStorage.removeItem('userInfo')
            //     MessageBox.confirm('当前登录状态已过期，请重新登录', '系统提示', {
            //         confirmButtonText: '登录',
            //         // showClose:false,
            //         showCancelButton:false,
            //         closeOnClickModal:false,
            //         type: 'warning'
            //     }
            //     ).then(() => {
            //         // 去登陆
            //         window.location.href = 'https://xxkj.glzhxx.com/homepage/#/login'
            //         flag=true
            //     }).catch(() => {
            //         flag=true
            //     })
            //     return
            // }else{
            //     Message({
            //         showClose: true,
            //         message: error.response.data.msg,
            //         type: 'error',
            //       })
                
            // }
            return Promise.reject(error)
        }
    }
    // Do something with response error


)

export {
    ajax,
    domain_static,
    basic_api_url,
    basic_static
}











