import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters';
import user from './modules/user';
import persistedState from 'vuex-persistedstate'
Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        user,
    },
    getters,
    plugins: [persistedState({ storage: window.sessionStorage })]
})
export default store;
