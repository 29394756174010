import { mapGetters } from "vuex";
// Toast
import { showToast } from 'vant';
import 'vant/es/toast/style';
import {isTips} from '@/util/index'
import {mobileMessage,passwordReset} from '@/api/login'
import {  encryption} from '@/util/index'
	export default {
		components: {},
		data() {
			var validatePass2 = (rule, value, callback) => {
				if (value == "") {
					callback(new Error("请确认新密码"));
				} else if (value != this.form.password) {
					callback(new Error("两次输入密码不一致!"));
				} else {
					callback();
				}
			};
			return {
				form: {
					phone: "",
					password: "",
					code: "",
					randomStr: "",
					appcode: "edu",
					confirm_password:'',
					mobileCode:''
				},
				showCode: false,
				num: 60,
				readonlyInput:true,
				readonlyInput1:true,
				readonlyInput2:true,
				rules: {
					phone: [
						{
							required: true,
							message: "请输入手机号",
							trigger: "blur",
						},
						{
							pattern:  /^1[2|3|4|5|6|7|8|9][0-9]{9}$/,
							message: "请输入正确手机号",
							trigger: "blur",
						},
					],
					password: [
						{
							required: true,
							message: "请输入新密码",
							trigger: "blur",
						},
						// {
						// 	pattern:  /^[a-zA-Z\d]{6,16}$/,
						// 	message: "请输入长度为6-16位的字母或数字",
						// 	trigger: "blur",
						// },
					],
					mobileCode: [
						{
							required: true,
							message: "请输入验证码",
							trigger: "blur",
						},
					],
					confirm_password: [
						{
							required: true,
							trigger: "blur",
							validator: validatePass2,
						},
					],
				},
				timer:null,
				key:'',
				type:'',

			};
		},
		computed: {},
		watch: {},
		methods: {
		

			cancelReadOnly () {
				this.readonlyInput = false;
			},
			cancelReadOnly1 () {
				this.readonlyInput1 = false;
			},
			cancelReadOnly2 () {
				this.readonlyInput2 = false;
			},
			async getCode() {
				if (this.form.phone == "") {
					return;
				}
				if(this.showCode)return
				let data = {
					mobile: this.form.phone,
				};
				this.showCode = true;
				let res = await mobileMessage(data);
				if (res) {
					this.showCode = true;
					let timer = setInterval(() => {
						this.num--
						if (this.num == 0) {
							this.showCode = false;
							clearInterval(timer)
						}
					}, 1000);
				}else{
					this.showCode=false
				}
			},
			login(formName) {
				this.$refs[formName].validate(async(valid) => {
					if (valid) {
						const user = encryption({
							data: this.form,
							key: '_Vf1hy9B7=-p(!@]',
							param: ['password']
						})
						let data={
							mobile:user.phone,
							password:user.password,
							mobileCode:user.mobileCode
						}
						let res=await passwordReset(data)
						if(res){
							isTips()
							if(this.isPc){
								this.$emit('close')				
							}else{
								this.$router.replace('/login')
							}
							// this.$router.replace('/homepage/index');

						}

					} else {
						console.log("error submit!!");
						return false;
					}
				});
			},
		},
		created() {
			
		},
		computed: {
			...mapGetters(["login_mess", "basic_static",'login_dialog','isPc']),
		},
		mounted() {
		
		},
		destroyed() {
			
		},
	};
