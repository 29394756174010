<template>
			<div :class="isPc?'pc_web':'mobile_app'">
				<!-- <p :class="isPc?'title text_20_bold':'title text_16_bold'">切换身份</p> -->
	<ul class="role-ul">
		<li :class="id == item.identityId ? 'flex_between active' : 'flex_between'" v-for="item in identitys"
			@click="changeRole(item)">
			<div class="flex_start flex_1">
				<img class="head" :src="item.identity ==3||item.identity ==4? 'https://cdn.tqxxkj.cn/static/learnspace/images/home/admin-head.png':(item.identity ==2||item.identity == 5||item.identity == 6)? 'https://cdn.tqxxkj.cn/static/learnspace/images/home/admin-head.png': item.identity == 1? 'https://cdn.tqxxkj.cn/static/learnspace/images/home/teacher-head.png': 'https://cdn.tqxxkj.cn/static/learnspace/images/home/student-head.png'" />
				<div class="flex_1">
					<div class="flex_between_0 flex_1">
						<p :class="isPc?'name text_16_bold':'name text_14_bold'">{{ item.userName }}</p>
					<button :class="item.identity ==3? 'role-button area-bg ':(item.identity ==2||item.identity == 5||item.identity ==6||item.identity ==4)? 'role-button school-bg ': item.identity == 1? 'role-button teacher-bg': 'role-button student-bg'" >{{
						item.identity == 5 ? '年级主任' : item.identity == 4 ? '学科研训员' :
				item.identity ==3
				? "区管理员"
				: item.identity ==2
				? "校管理员"
				: item.identity == 1
				? "教师"
				: item.identity == 4
				? "学科研训员"
				: item.identity == 6
				? "集团校管理员"
				: "学生"
				}}</button>
					</div>
					<p :class="isPc?'school text_16':'school text_14'">{{ item.identity == 6&&item.groupSchoolName?item.groupSchoolName:item.gsiName }}</p>
				</div>
			</div>
			
				<img class="radio" :src="id == item.identityId ?'https://cdn.tqxxkj.cn/static/learnspace/images/home/radio-active.png':'https://cdn.tqxxkj.cn/static/learnspace/images/home/radio.png'" />
		</li>
	</ul>
			</div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
	    import { removeCookie, getCookie, setCookie } from "@/util/index";
	import { getToken, getInfoDetail,getIdentity } from "@/api/login";
	export default {
		data() {
			return {
				identitys: [],
				user: {},
				id: "",
			};
		},
		mounted() {
	
		 },
		 computed: {
        ...mapGetters(['login_mess','basic_static','isPc']),    
    },
		created() {		
			this.getIdentity()	
			// if (this.identitys.length > 0) {
			// 	this.identitys.forEach((item) => {
			// 		if (item.isCurrent == 1) {
			// 			this.id = item.identityId;
			// 			this.identityName=item.identityName
			// 		}
			// 	});
			// }
		},
		methods: {
			async getIdentity(){
				let data={
					
				}
				let res=await getIdentity(data)
				if(res){
					this.identitys=res.data
					res.data.forEach((item) => {
						if (item.isCurrent) {
							this.id = item.identityId;
						}
					})
			}
		},
			async getInfoDetail() {
				let res = await getInfoDetail();
				if (res) {
					this.id=res.data.identityId
					localStorage.setItem("userInfo", JSON.stringify(res.data));
					setCookie('userId',res.data.userId+'')
					// this.id = item.identityId;
					// 	this.identity=res.data.identity
							this.$store.commit("set_login_mess", res.data);
					// this.identitys=res.data.identitys
					// res.data.identitys.forEach((item) => {
					// 	if (item.isCurrent == 1) {
					// 		this.id = item.identityId;
					// 	this.identityName=item.identityName
					// 		this.$store.commit("set_login_mess", item);
					// 	}
					// });

					if(this.$route.path=='/homepage/index'){
					// location.reload();
					}
					// else if(this.$route.path.indexOf('application')>-1){
					// 	this.$router.replace('application/front/index')
					// }
					else{
					this.$router.replace('/homepage/index')
					}
					this.$emit('close')
					// location.reload()
				}
			},
			async changeRole(item) {
				localStorage.removeItem("userInfo");
				let res = await getToken(item);
				if (res) {
					setCookie("access-token", res.data,{path:'/'});
					this.getInfoDetail();
				}
			},
		},
	};
</script>
<style lang="scss" scoped>
	@import "../style/element.scss";
	.pc_web{
		.title{
	
	color: $grey1;
	position: relative;
	margin-bottom: 24px;
		}
		.role-button{
			min-width: 88px;
	height: 28px;
	border-radius: 12px;
	border: 0;
	font-size: 14px;
	padding: 0 10px;
		}
	.area-bg{
		color: #4260FD;
		background: #E9ECFF;
	}
	.school-bg{
		background: rgba(124,59,255,0.1);
		color: #7C3BFF;
	}
	.teacher-bg{
		background: rgba(255,165,50,0.1);
		color: #FFA532;
	}
	.student-bg{
		background: rgba(35,212,134,0.1);
		color: #23D486;
	}
		.role-ul {
			max-height: 440px;
			overflow-y: auto;
			li {
				background: #FFFFFF;
	border-radius: 12px;
	border: 1px solid #E2E3E6;
				width: 100%;
				padding: 24px;
				margin-bottom: 16px;
				cursor: pointer;
	
				.head {
					width: 56px;
					height: 56px;
					border-radius: 50%;
					margin-right: 16px;
				}
				.radio{
					width: 24px;
					height: 24px;
					margin-left: 32px;
				}
				.name {
					color: $grey1;
					margin-bottom: 8px;
				}
	
				.school {
					color: #B4B6BE;
				}
			}
	
			.active {
				border: 2px solid #23D486;
				box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.08);
			}
	
			li:last-child {
				margin-bottom: 0;
			}
		}
	}

	.mobile_app{
		.title{
	
	color: $grey1;
	position: relative;
	margin-bottom: 24px;
		}
		.role-button{
			width: 80px;
	height: 24px;
	border-radius: 12px;
	border: 0;
	font-size: 12px;
		}
	.area-bg{
		color: #4260FD;
		background: #E9ECFF;
	}
	.school-bg{
		background: rgba(124,59,255,0.1);
		color: #7C3BFF;
	}
	.teacher-bg{
		background: rgba(255,165,50,0.1);
		color: #FFA532;
	}
	.student-bg{
		background: rgba(35,212,134,0.1);
		color: #23D486;
	}
		.role-ul {
			max-height: 440px;
			overflow-y: auto;
			li {
				background: #FFFFFF;
	border-radius: 12px;
	border: 1px solid #E2E3E6;
				width: 100%;
				padding: 20px 16px;
				margin-bottom: 16px;
				cursor: pointer;
	
				.head {
					width: 40px;
					height: 40px;
					border-radius: 50%;
					margin-right: 8px;
				}
				.radio{
					width: 24px;
					height: 24px;
					margin-left: 16px;
				}
				.name {
					color: $grey1;
				}
	
				.school {
					color: #B4B6BE;
				}
			}
	
			.active {
				border: 2px solid #23D486;
				box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.08);
			}
	
			li:last-child {
				margin-bottom: 0;
			}
		}
	}

</style>