<template>
	<header>
		<div>
			<div class="main top flex_between">
				<!-- <img class="logo-title" :src="basic_static + 'learnspace/images/login-new-1.png'" /> -->
				<img class="logo-title" :src="basic_static + 'learnspace/images/home/logo.png'" />
				<div class="flex_end flex_1">
					<div class="meun top" v-if="this.menu_list.length>0">
						<ul class="flex_end">
							<li :class="menu_id == item.id ? 'zy-li active' : 'zy-li'" @click="changeMeun(item)"
								v-for="item in menu_list">
								{{ item.title }}
							</li>
						</ul>
					</div>
					<div class="el-dropdown-link flex_start head">
						<img :src="basic_static + 'learnspace/images/home/default-head.png'" class="logo" alt="logo" />
						<p>{{ login_mess.userName }}</p>
						<img @click="$router.replace('/homepage/my')" :src="basic_static + 'learnspace/images/grzx.png'"
							class="grzx-img" />
					</div>
				</div>
			</div>
		</div>
	</header>
</template>
<script>
	import { mapGetters, mapActions } from "vuex";
	export default {
		data() {
			return {
				menu_list: [],
				menu_id: "",
				role_id: "",
				dialogVisible: false,
				userInfo: {},
			};
		},
		watch: {
			login_mess: {
				handler: function (val, oldVal) {
					this.init();
				},
				// 深度观察监听
				deep: true,
			},
			$route: {
				handler: function (val, oldVal) {
					this.init();
				},
				// 深度观察监听
				deep: true,
			},
		},
		methods: {
			toMy() {
				this.$router.push("/homepage/my");
			},
			init() {
				this.menu_id = this.$route.path;
				if ((this.$route.path.indexOf("resource") > -1&&this.$route.path.indexOf("/space/resource/detail/")==-1)&&this.$route.meta.noHeader) {
					this.menu_list = [
						{
							id: "/space/resource/index/zy",
							title: "资源",
							path: "/space/resource/index/zy",
						},
						{
							id: "/space/resource/index/tskc",
							title: "特色课程",
							path: "/space/resource/index/tskc",
						},
						{ id: "/space/resource/mskzkt", title: "空中课堂", path: "/space/resource/mskzkt" },
						{ id: "jlwx", title: "金陵微校", path: "https://jlwx.jsnje.cn/home" },
					];
				} else if (this.$route.path.indexOf("collect") > -1&&this.$route.meta.noHeader) {
					this.menu_list = [
						{
							id: "/space/collect/index/zy",
							title: "资源",
							path: "/space/collect/index/zy",
						},
						{
							id: "/space/collect/index/tskc",
							title: "特色课程",
							path: "/space/collect/index/tskc",
						},
					];
				} else if ((this.$route.path == '/space/bk/index'||this.$route.path == '/space/bk/mykit'||this.$route.path == '/space/bk/xkw'||this.$route.path == '/space/bk/person')&&this.login_mess.identity == 1) {
					this.menu_list = [
						{
							id: "/space/bk/index",
							title: "备课中心",
							path: "/space/bk/index",
						},
						{
							id: "/space/bk/person",
							title: "个人空间",
							path: "/space/bk/person",
						},
						{
							id: "/space/bk/xkw",
							title: "学科网备课",
							path: "/space/bk/xkw",
						},
						{
							id: "/space/bk/mykit",
							title: "课例包",
							path: "/space/bk/mykit",
						},
					];
				}else if((this.$route.path == '/space/tk/index'||this.$route.path == '/space/tk/workmould')&&this.login_mess.identity == 1){
					this.menu_list = [
						{
							id: "/space/tk/index",
							title: "题库",
							path: "/space/tk/index",
						},
						{
							id: "/space/tk/workmould",
							title: "作业模板",
							path: "/space/tk/workmould",
						},
					];
				}else{
					this.menu_list=[]
				}
			},
			close() {
				this.dialogVisible = false;
			},
			changeMeun(item) {
				this.menu_id = item.id;
				if (item.id == "jlwx") {
					window.open(item.path);
				} else {
					this.$router.push(item.path);
				}
			},
		},
		created() {
			this.init();
		},
		activated() {
			// this.init()
		},
		components: {},
		computed: {
			...mapGetters(["basic_static", "domain_static", "login_mess"]),
		},
	};
</script>
<style lang="scss" scoped>
	header {
		background: #fff;
	}

	.grzx-img {
		width: 136px;
		margin-left: 30px;
		cursor: pointer;
	}

	.many-margin {
		margin-bottom: 60px;
	}

	.top {
		// padding: 9px 0 9px;
		height: 64px;

		.logo-title {
			height: 48px;
		}

		.head {
			margin-left: 20px;

			.logo {
				width: 34px;
				height: 34px;
				margin-right: 8px;
			}

			p {
				font-size: 14px;
font-family: PingFang-SC-Bold, PingFang-SC;
font-weight: bold;
color: #2B2C33;
line-height: 20px;
			}
		}
	}

	.meun {
		// background: #4e72f3;
		/* 主色蓝 */

		// border: 1px solid #4E72F3;
		// width: 100%;
		font-size: 20px;
		font-size: 16px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #2B2C33;
line-height: 24px;
		// width: 50%;
		flex: 1;

		li {
			cursor: pointer;
			// padding: 24px 70px;
			// width: 16.6%;
			// width: 20%;
			white-space: nowrap;
			text-align: center;
			// margin-right: 60px;
			position: relative;
		}

		// padding-bottom: 5px;
		// .zy-li{padding-bottom: 6px;}
		.zy-li {
			padding: 20px 16px;
		}

		.zy-li.active {
			font-size: 16px;
font-family: PingFang-SC-Bold, PingFang-SC;
font-weight: bold;
color: #FFFFFF;
line-height: 24px;
			font-weight: bold;
			background: #4260FD;
border-radius: 8px 8px 0px 0px;
		}

		// .zy-li.active::before{
		//   content: '';
		//   position: absolute;
		//   background: #fff;
		//   width: 100%;
		//   height: 3px;
		//   left: 0;
		//   bottom: 0;
		// }
	}

	.bk-li {
		border-radius: 100px;
		background: #fff;
		font-size: 18px;
		color: #4e72f3;
		padding: 5px 20px;
		// margin-right: 6%;
		margin: 20px 6% 20px 0;
	}
</style>