import { loginByUsername, getQwKey, getQwCode, getQwState } from "@/api/login";
import { encryption, setCookie } from "@/util/index";
import { mapGetters } from "vuex";
export default {
    components: {},
    data() {
        return {
            form: {
                username: "",
                password: "",
                code: "",
                randomStr: "",
                appcode: "edu",
            },
            img_url: "",
            readonlyInput:true,
            readonlyInput1:true,
            rules: {
                username: [
                    {
                        required: true,
                        message: "请输入用户名",
                        trigger: "blur",
                    },
                    // {
                    // 	pattern:  /^1[2|3|4|5|6|7|8|9][0-9]{9}$/,
                    // 	message: "请输入y",
                    // 	trigger: "blur",
                    // },y
                ],
                password: [
                    {
                        required: true,
                        message: "请输入密码",
                        trigger: "blur",
                    },
                    // {
                    // 	pattern:  /^[a-zA-Z\d]{6,16}$/,
                    // 	message: "请输入长度为6-16位的字母或数字",
                    // 	trigger: "blur",
                    // },
                ],
            },
            timer: null,
            type:'企业微信',
            showCode: false,
        };
    },
    computed: {},
    props:[''],
    watch:{
        login_dialog:function(){
        // this.$store.commit('set_login_dialog',this.dialogVisibleLogin)
        if(!login_dialog){
            clearTimeout(this.timer);
        }
    },
},
computed: {
        ...mapGetters(["login_mess", "basic_static",'login_dialog','isPc']),
    },
    methods: {
        // cancelReadOnly () {
        // 	this.readonlyInput = false;
        // },
        // cancelReadOnly1 () {
        // 	this.readonlyInput1 = false;
        // },
        setPsw(){
            this.$emit('setPsw')
        },
        async renewalCode() {
            await this.getQwKey();
            this.timer = setInterval(() => {
                this.getQwState();
            }, 2000);
            this.showCode = false;
        },
        async getQwKey() {
            let res = await getQwKey();
            if (res) {
                this.key = res.data;
                this.getQwCode();
            }
        },
        async getQwState() {
            let data = {
                key: this.key,
            };
            let res = await getQwState(data);
            if (res) {
                if (res.data.state == -1) {
                    this.showCode = true;
                    clearTimeout(this.timer);
                } else if (res.data.state == 1) {
                    setCookie("access-token", res.data.accessToken);
                    location.reload()
                    // window.location.href = "https://xxkj.glzhxx.com/studyspace/#/homepage/index";

				}
			}
		},
		async getQwCode() {
			let data = {
				key: this.key,
				width: 260,
				height: 260,
			};
			let res = await getQwCode(data);
			if (res) {
				this.img_url = res;
			}
		},
		changeType(type) {
			this.type=type
			// this.currentType = this.currentType == "code" ? "input" : "code";
			// this.typeSrc =
			// 	this.currentType == "code"
			// 		? require("../assets/login/pc.png")
			// 		: require("../assets/login/code.png");

            if (this.type== "企业微信") {
                this.getQwState();
                this.timer = setInterval(() => {
                    this.getQwState();
                }, 2000);
            } else {
                clearTimeout(this.timer);
            }
        },

        login(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    const user = encryption({
                        data: this.form,
                        key: "_Vf1hy9B7=-p(!@]",
                        param: ["password"],
                    });
                    let res = await loginByUsername(
                        user.username,
                        user.password,
                    );
                    if (res) {
                        setCookie("access-token", res.data);
                        await this.$emit('close','login')
                        if(this.isPc){
                            this.$store.commit('set_login_dialog',false)
                            if(this.$route.path.indexOf('/space/bk')>-1&&this.$route.path!='/space/bk/code'&&this.$route.path!='/space/bk/index'){
                                this.$router.replace('/space/bk/index')
                                // window.location.replace('https://xxkj.glzhxx.com/studyspace/#/space/bk/index')
                            }else if(this.$route.path.indexOf('/resource')>-1&&this.$route.path!='/resource/index'){
                                this.$router.replace('/resource/index')
                                // window.location.replace('https://xxkj.glzhxx.com/studyspace/#/resource/index')
                            }else if(this.$route.path.indexOf('/application')>-1&&this.$route.path!='/application/front/index'){
                                this.$router.replace('/application')
                                // window.location.replace('https://xxkj.glzhxx.com/studyspace/#/application/index')
                            }else{
                                location.reload()
                            }
                        }else{
                            this.$router.replace('/homepage/index')
                        }
                      
                    }
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
    },
    async created() {
        if(this.isPc){
        this.type='企业微信'
        await this.getQwKey();
        await this.getQwState();
                this.timer = setInterval(() => {
                    this.getQwState();
                }, 2000);
        }else{
        this.type='账号'
        }
    },
    mounted() {
    
     },
    destroyed() {
        clearTimeout(this.timer);
    },
};
